.sellerlogin_page .heading_top:before {
    content: "LOGIN AS A SELLER";
    position: absolute;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3A3F4A;
    color: transparent;
    font-size: 50px;
    font-family: "montserret_sb";
    z-index: 0;
    bottom: 4px;
    left: 2px;
    white-space: nowrap;
}

section.sellerlogin_page .heading_top {
    text-align: left;
}

section.sellerlogin_page {
    background: #111419;
    padding: 7rem 0;
    /* height: 100vh; */
    padding-top: 10rem;
}

section.sellerlogin_page .wrapper .form-start {
    margin-top: 3rem;
}

.sellerlogin_page span.notamember {
    color: #fff;
    font-size: 14px;
}

.sellerlogin_page span.register {
    color: #aceb1e;
    font-family: "montserret";
    font-size: 14px;
}

.sellerlogin_page p.welcome_para {
    color: #fff;
    font-size: 26px;
    font-family: "worksans-l";
    margin-bottom: 0;
    margin-top: 3rem;
    line-height: 1.2;
}

.sellerlogin_page .rememberDv .form-group input#remember {
    width: auto;
}

.sellerlogin_page .rememberDv .form-group {
    display: flex;
    gap: 10px;
    align-items: center;
}

section.sellerlogin_page .plants_btn {
    background-color: #ACEB1E;
    border-radius: 0;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    color: #000;
    font-family: "montserret";
    font-size: 14px;
    font-weight: 500;
    padding: 14px 40px;
    text-decoration: none;
    text-decoration: none !important;
    transition: .6s;
    width: 100%;
}

section.sellerlogin_page .plants_btn:hover {
    color: #ACEB1E;
    border: 1px solid #ACEB1E;
    background: transparent;
}

.sellerlogin_page .rememberDv .form-group label {
    margin-bottom: 0;
    color: #fff;
}

.sellerlogin_page .rememberDv {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.sellerlogin_page .rememberDv .lnk a {
    color: #aceb1e;
    font-size: 14px;
}

.sellerlogin_page .form-start .form-group input {
    background: transparent;
    border-radius: 0;
    padding: 21px;
    border: 1px solid;
}

.sellerlogin_page section.sellerlogin_page .form-start {
    margin-top: 3rem;
}

.sellerlogin_page span.plantshead {
    color: #aceb1e;
    font-family: "montserret";
}

/* custom check box start here*/
.sellerlogin_page .custom_checkbox {
    display: block;
    margin-bottom: 15px;
}

.sellerlogin_page .custom_checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.sellerlogin_page .custom_checkbox label {
    position: relative;
    cursor: pointer;
}

.sellerlogin_page .custom_checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #98A1BC;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.sellerlogin_page .custom_checkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid #ACEB1E;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* custom check box start here*/
.sellerlogin_page .login_img img {
    transform: scale(1.2);
    margin-left: 8rem;
}

section.sellerlogin_page .heading_top h3 {
    color: #aceb1e;
    position: relative;
    font-family: 'montserret_b';
}

@media(max-width:1280px) {
    .sellerlogin_page .login_img img {
        transform: scale(1);
    }
}

@media(max-width:1100px) {
    section.sellerlogin_page .heading_top h3 {
        font-size: 30px;
        color: #aceb1e;
    }

    .sellerlogin_page .heading_top:before {
        font-size: 45px;
        bottom: 12px;
    }

    .sellerlogin_page .login_img img {
        transform: scale(1);
        margin-left: 4rem;
    }

}

@media(max-width:1024px) {
    .sellerlogin_page .login_img img {
        transform: scale(.9);
        margin-left: 3rem;
    }
}