/* section about us top start here */
section.aboutus_section {
    background: url(../images/bannerright.png) no-repeat;
    background-position-x: 205px;
    background-position-y: 45px;
}

section.aboutus_page .heading_top:before {
    content: "ABOUT US";
    position: absolute;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3A3F4A;
    color: transparent;
    font-size: 60px;
    font-family: "worksans-b";
    z-index: 0;
    bottom: 36px;
    left: 0;
    right: 0;
}

section.aboutus_page .heading_top {
    position: relative;
    text-align: center;
}

section.aboutus_page .heading_top p {
    font-size: 14px;
    padding: 0 14rem;
}

section.aboutus_page {
    background: linear-gradient(180deg, rgba(17, 20, 25, 1) 0%, rgba(18, 21, 26, 1) 100%);
    padding-top: 8rem;
}

section.aboutus_sec {
    margin: 5rem 0 10rem;
}

.welcometoplants h3 {
    font-family: "montserret_sb";
    color: #fff;
    text-transform: uppercase;
}

.welcometoplants p {
    font-size: 14px;
}

.weltoplant_sec h6 {
    font-family: "montserret_r";
    color: #fff;
    margin-bottom: 0;
    text-transform: uppercase;
}

.weltoplant_sec {
    display: flex;
    gap: 20px;
    margin-top: 4rem;
}

.weltoplant_sec p {
    font-size: 13px;
    margin-bottom: 0;
}

.weltoplant_sec img {
    width: 20px;
}

.about_img_wrapper {
    text-align: right;
    position: relative;
}

.about_img_wrapper figure img {
    border-radius: 10px;
    /* width: 100%; */
    height: 360px;
}

.about_img_wrapper figure {
    position: relative;
}

.about_img_content span {
    background: #fff;
    color: #000;
    padding: 8px 45px 7px 9px;
    position: relative;
    font-family: "montserret";
}

.about_img_content i {
    color: #fff;
    background: #aceb1e;
    padding: 9px 10px;
}

.about_img_content {
    position: absolute;
    transform: translate(131px, 15rem);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate(131px, 15rem);
    -moz-transform: translate(131px, 15rem);
    -ms-transform: translate(131px, 15rem);
    -o-transform: translate(131px, 15rem);
}

.about_img_wrapper figure img.phone_icon {
    width: 24px;
    /* margin-right: 9px; */
    height: 24px;
}

.about_img_wrapper .phoneicon {
    background: #ACEB1E;
    padding: 6px;
    padding-top: 7px;
}

.about_img_content span:after {
    content: "";
    position: absolute;
    border-top: 2px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 33px solid transparent;
    right: 0;
}

.about_img_wrapper .leaf1 {
    position: absolute;
    right: 0;
    animation: mover 1s infinite alternate;
}

/* section about us top end here */
/* misssion statement start here */
section.aboutus_page section.mission_statement_sec .heading_top:before {
    content: "MISSION STATEMENT" !important;
    position: absolute;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3A3F4A;
    color: transparent;
    font-size: 60px;
    font-family: "worksans-b";
    z-index: 0;
    bottom: 0;
    left: 4rem;
    right: 0;
}

section.aboutus_page section.mission_statement_sec .heading_top {
    position: relative;
    text-align: left;
}

section.aboutus_page .section.mission_statement_sec .heading_top p {
    font-size: 14px;
    padding: 0 14rem;
}

/* misssion statement end here */
/* section most popular start here */
section.aboutus_page .most_popular_sec .heading_top:before {
    content: "MOST POPULAR";
    position: absolute;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3A3F4A;
    color: transparent;
    font-size: 60px;
    font-family: "worksans-b";
    z-index: 0;
    bottom: 0;
    left: 4rem;
}

section.aboutus_page .most_popular_sec .heading_top {
    position: relative;
    text-align: left;
}

section.aboutus_page .most_popular_sec .heading_top p {
    font-size: 14px;
    padding: 0 14rem;
}

/* section most popular end here */
@media(max-width:1440px) {
    section.aboutus_page .heading_top p {
        padding: 0 5rem;
    }

    section.aboutus_section {
        background-size: 10px 176px;
    }
}

@media(max-width:1366px) {
    section.aboutus_page .heading_top p {
        padding: 0 7rem;
    }
}

@media(max-width:1280px) {
    section.aboutus_page .heading_top p {
        padding: 0 8rem;
    }

    section.aboutus_section {
        background-size: 10px 161px;
    }
}

@media(max-width:1100px) {
    section.aboutus_page .heading_top p {
        padding: 0 6rem;
    }
}

@media(max-width:1024px) {
    section.aboutus_page .heading_top p {
        padding: 0 4rem;
    }
}