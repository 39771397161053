section.shop_page {
    padding-top: 8rem;
    background-color: #111419;
    background-image: url(../images/bannerright.png);
    background-position-x: 205px;
    background-position-y: 120px;
    background-repeat: no-repeat;
}

section.shop_page .heading_top:before {
    content: "SHOP NOW";
    position: absolute;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3A3F4A;
    color: transparent;
    font-size: 60px;
    font-family: "worksans-b";
    z-index: 0;
    /* bottom: 48px; */
    left: 0;
    right: 0;
    top: -50px;
}

section.shop_page .heading_top {
    text-align: center;
}

section.shop_page .heading_top p {
    font-size: 13px;
    margin-top: 1rem;
    margin-bottom: 6rem;
}