.accounts_page .heading_top:before {
  content: "MY ACCOUNT";
  position: absolute;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #3A3F4A;
  color: transparent;
  font-size: 60px;
  font-family: "worksans-b";
  z-index: 0;
  bottom: 40px;
  left: 0;
  right: 0;
}

.accounts_page .heading_top {
  position: relative;
  text-align: center;
}

.accounts_page .heading_top p {
  font-size: 14px;
  padding: 0 10rem;
}

section.accounts_page {
  padding-top: 10rem;
  background: linear-gradient(0deg, #1e2129, #111419);
}

.nav {
  display: block;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
  color: #fff;
  font-family: 'montserret_sb';
  text-decoration: none;
  font-weight: 700;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

section.account-sec .row.account .leftside_account .nav-pills .nav-link.active,
section.account-sec .row.account .leftside_account .nav-pills .show>.nav-link {
  color: #fff;
  background-color: transparent;
}

section.accounts_page .tab-content {
  padding: 3rem 1rem;
  background: linear-gradient(180deg, rgba(30, 33, 41, 1) 0%, rgba(17, 20, 25, 1) 100%);
}

section.accounts_page div#pills-tabContent {
  padding: 0;
}

section.accounts_page .all-form-one {
  padding: 3rem;
  background: linear-gradient(180deg, rgba(30, 33, 41, 1) 0%, rgba(17, 20, 25, 1) 100%);
}

.all-form-one .form-group input {
  border: none;
  background: unset;
  color: #fff;
  padding-left: 0;
  font-family: 'montserret_r';
}

.all-form-one .form-group p {
  border: none;
  background: unset;
  color: #fff;
  padding-left: 0;
  font-family: 'montserret_r';
}

.all-form-one .form-group label {
  font-size: 11px;
  color: #eeeeeea6;
  font-family: 'montserret_r';
  font-weight: 400;
  background: transparent;
}

.all-form-one .form-group input::placeholder {
  font-size: 16px;
  color: #fff;
  font-family: 'montserret_m';
  /* font-weight: 600; */
}

.all-form-one .row {
  display: flex;
  align-items: center;
}

.all-form-one .form-head h3 {
  font-size: 22px;
  color: #fff;
  font-family: 'montserret_b';
  /* font-weight: 400; */
}

.all-form-one .editprofilebtn a {
  padding: 14px 20px;
  color: #000;
}

section.accounts_page .tab-content .tab-pane .change-password a {
  color: #7BC145;
  font-size: 14px;
  text-decoration: underline;
}

section.accounts_page .tab-content .tab-pane .change-password {
  margin-bottom: 1rem;
}

section.accounts_page .tab-content .tab-pane {
  border: 0;

}

section.account-sec .row.account .leftside_account {
  border-right: 1px solid #CCCCCC;
  background: #111419;
}

section.account-sec .row {
  display: flex;
  /* align-items: center; */
}

section.account-sec .row.account {
  border: 1px solid #98A1BC;
}

.john-dow-all .john-img {
  margin-top: -62px;
}

.john-dow-all .john-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #aceb1e;
}

.john-dow-all {
  text-align: center;
  border-bottom: 1px solid #70707038;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.edit-profile button {
  padding: 10px 26px;
  font-size: 13px;
  line-height: 1.5;
  color: #000;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: 1px solid #ACEB1E;
  font-family: "worksans-sb";
}

section.account-sec {
  padding: 70px;
}

.john-text h3 {
  font-size: 25px;
  color: #ACEB1E;
  font-family: 'montserret_b';
  /* font-weight: 700; */
}

.john-dow-all {
  text-align: center;
  border-bottom: 1px solid #70707038;
}

section.account-sec label {
  color: #fff;
  font-family: "montserret_r";
  font-size: 13px;
}


/* ************* */

/* order table starts here */
section.accounts_page .all-form-two {
  padding: 3rem 4rem;
  background: linear-gradient(180deg, #1e2129, #111419);
}

section.accounts_page .order-table-wrapper {
  padding-top: 40px;
  padding-left: 7rem;
  padding-bottom: 6rem;
  background: linear-gradient(180deg, rgba(30, 33, 41, 1) 0%, rgba(17, 20, 25, 1) 100%);
}

.order-table-wrapper tr.row1 th {
  padding: 23px 20px;
  text-align: center;
  /* font-weight: 700; */
  font-size: 14px;
  color: #fff;
  font-family: "montserret_sb";
}

.order-table-wrapper tr.row2 {
  border: 1px solid #98A1BC;
  background: #111419;
}

.order-table-wrapper tr.row2 td {
  padding: 18px 20px;
  text-align: center;
}

.profile .order-table-wrapper tr.row2 td {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
}

.profile .order-table-wrapper tr.row2 td:first-child {
  border-left: 1px solid #c7c7c7;
}

.profile .order-table-wrapper tr.row2 td:last-child {
  border-right: 1px solid #c7c7c7;
}

.order-table-wrapper .row2 td {
  color: #fff;
  font-family: "montserret_r";
  font-size: 12px;
  /* font-weight: 600; */
}

.profile .order-table-wrapper td.paid {
  color: #00bc26;
}

.order-table-wrapper .order-details a {
  color: #ACEB1E;
  text-transform: uppercase;
  font-family: "montserret";
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
}

.pagination-wrapper a.page-link {
  display: flex;
  line-height: 0px;
  height: unset;
  margin: 5px;
  padding: 14px;
  justify-content: center;
  font-family: 'montserret';
  font-size: 18px;
  color: #6e6e6e;
  font-weight: 400 !important;
  border: 1px solid #6e6e6e;
}

.pagination li a i {
  line-height: 0px;
}

ul.pagination li.page-item:first-child a.page-link,
ul.pagination li.page-item:last-child a.page-link {
  border-radius: 0px !important;
  background: transparent;
  border: 0px;
  color: #6D6D6D;
  font-size: 25px;
}

.pagination-wrapper .active a.page-link {
  color: #ffffff;
  background: transparent;
  border-color: #7BC145;
  background-color: #7BC145;
}

/* order responsive start here */
@media (max-width: 1100px) {
  .order-table-wrapper tr.row1 th {
    padding: 23px 9px;
    font-size: 14px;
  }

  .order-table-wrapper .row2 td {
    font-size: 14px;
  }
}

@media (max-width: 1018px) {
  .order-table-wrapper .row2 td {
    font-size: 13px;
  }
}

@media (max-width: 700px) {
  .order-table-wrapper tr.row2 td {
    padding: 9px 7px;
  }
}

/* order responsive ends here */
/* order table ends here */
/* modal css starts here */
.profile-row button.close span {
  color: #f35f63;
}

.profile .modal-dialog {
  max-width: 41%;
}

.close {
  opacity: 1;
}

.modal-header .close {
  margin: 0;
}

.model-header {
  text-align: center;
  padding: 20px 11px;
}

.profile-row h5#exampleModalLongTitle {
  font-size: 43px;
  font-family: "montserret";
  color: #f35f63;
}

.profile-row .model-header h6 {
  color: #2a263d;
  font-family: "montserret";
  font-size: 37px;
  font-weight: 400;
}

.model-header button.close {
  margin-top: -92px;
  margin-right: 24px;
}

.product-deatail-wrapper {
  padding: 0px 30px;
  display: flex;
  width: 100%;
  align-items: center;
}

.modal-open .modal {
  overflow: scroll !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.modal-open .modal::-webkit-scrollbar {
  display: none;
}

.product-img {
  flex: 2;
}

.product-content {
  flex: 5;
}

.product-price {
  flex: 1;
}

.profile .modal-body h6 {
  color: #f35f63;
  font-family: "montserret";
  font-size: 20px;
}

h6.product-price {
  font-size: 21px;
  font-weight: 600;
}

h6.product-size {
  color: #1f2c35 !important;
  font-weight: 400;
  font-size: 17px !important;
}

h5.product-name {
  font-size: 16px;
  font-weight: 500;
}

.profile .profile-row .my-orders-products {
  height: 230px;
  overflow-y: scroll;
}

.profile .profile-row .my-orders-products::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.product-deatail-wrapper {
  margin: 20px auto !important;
  width: 80%;
}

.product-deatail-wrapper.active {
  border-right: 3px solid #ef5d61;
  margin: 10px 0px;
}

.order-details-wrapper {
  padding: 0px 32px;
}

.order-details-wrapper h6 {
  display: flex;
  justify-content: space-between;
  color: #000000 !important;
  font-size: 17px !important;
  font-weight: 500;
}

.order-details-wrapper {
  padding: 0px 80px;
}

/* responsive starts here */
@media (max-width: 1345px) {
  .profile .modal-dialog {
    max-width: 51% !important;
  }
}

@media (max-width: 1130px) {
  .profile .modal-dialog {
    max-width: 61% !important;
  }
}

@media (max-width: 936px) {
  .profile .modal-dialog {
    max-width: 71% !important;
  }
}

@media (max-width: 822px) {
  .profile .modal-dialog {
    max-width: 81% !important;
  }
}

@media (max-width: 758px) {
  .product-deatail-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .product-deatail-wrapper.active {
    border-right: none;
    border-bottom: 3px solid #ef5d61;
    margin: 10px 0px;
  }
}

@media (max-width: 563px) {
  .profile .modal-dialog {
    max-width: 81% !important;
    margin: 30px auto;
  }

  .order-details-wrapper h6 {
    display: inline-block;
  }

  .order-details-wrapper {
    padding: 0px 52px;
    text-align: center;
  }
}

/* responsive ends here */
/* modal css ends here */

/* wishlist css starts here */
.wishlist-table-wrapper {
  margin-top: 40px;
}

.wishlist-table-wrapper tr.row1 th {
  padding: 13px;
  background: #f2f2f2;
  font-family: "montserret";
  font-size: 12px;
  color: #000;
  text-align: center;
}

.wishlist-table-wrapper tr.row1 th.wish-img {
  width: 16%;
}

.wishlist-table-wrapper td.wishlist-product h4 {
  color: #1f2c35;
  font-family: "montserret";
  font-size: 15px;
  font-weight: 500;
}

.wishlist-table-wrapper td.wishlist-price h6 {
  color: #88BE4C;
  font-size: 16px;
  font-family: "montserret";
  font-weight: 600;
}

.wishlist-table-wrapper td.wishlist-cancel i {
  color: #000000;
  font-size: 23px;
}

.wishlist-table-wrapper tr.row2 td {
  margin: 0;
  padding: 0;
  text-align: center;
  padding: 20px;
}

.wishlist-table-wrapper tr.row2 {
  border-bottom: 1px solid #707070;
}

.wishlist-table-wrapper tr.row2:last-child {
  border-bottom: none;
}

/* responsive css starts here */
@media (max-width: 600px) {
  .wishlist-table-wrapper tr.row1 th {
    text-align: center;
  }

  .wishlist-table-wrapper tr.row2 td {
    text-align: center;
  }
}

/* wishlist css ends here */
/* **************** */

td.total-price a {
  color: #7BC145;
}

.for-blue-color a {
  color: #1050FF !important;
}

.for-right {
  float: right;
}

.form-head h3 {
  font-size: 20px;
  color: Edit Account;
  color: #fff;
  font-family: 'montserret';
  font-weight: 400;
}

.wishlist-table-wrapper h3 {
  font-size: 20px;
  color: #2A263D;
  font-family: 'montserret';
  font-weight: 400;
}

section.accounts_page .all-form-three {
  padding: 3rem 5rem;
}

.all-form-three input#exampleInputEmail1::placeholder {
  font-size: 10px;
  color: #636060a1;
  font-family: 'Poppins';
  font-weight: 400;
  /* text-align: center; */
}

.fomr-three-btn button {
  width: 100%;
}

/* .all-form-width {
  width: 40%;
} */
.all-form-width .form-control {
  background: #111419;
  color: #fff;
  border: 1px solid #98A1BC;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.fomr-three-btn button.plant_btn {
  color: #000;
  font-family: 'worksans-sb';
  width: fit-content;
  margin-top: 1rem;
}

.editprofilebtn button.plant_btn {
  width: fit-content;
  padding: 10px 26px;
  font-size: 13px;
  line-height: 1.5;
  color: #000;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: 1px solid #ACEB1E;
  font-family: "worksans-sb";
}

.editprofilebtn {
  text-align: right;
}

/* modal css start here */
section.accounts_page .cart-product-detail {
  background-color: #111419;
  /* border: 1px solid #98a1bc; */
  overflow: hidden;
  padding: 50px;
  position: relative;
}

.accounts_page h3 {
  color: #fff;
  font-family: "montserret_b";
  /* font-size: 20px; */
  font-weight: 700;
}

.accounts_page .cart-product-detail .checkout-products {
  height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  border-bottom: 2px solid #c5c5c5;
}

.accounts_page .cart-product-detail .products {
  align-items: center;
  border-bottom: 1px solid #70707073;
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  position: relative;
}

.accounts_page .cart-product-detail .br-right:before {
  align-items: center;
  background: #1aac7a;
  content: "";
  display: flex;
  height: 60%;
  position: absolute;
  right: -20px;
  width: 4px;
}

.accounts_page .cart-product-detail .products .abt-product {
  align-items: center;
  display: flex;
}

.accounts_page .cart-product-detail .products .img-box figure {
  margin-bottom: 0;
}

.abt-product .img-box img {
  border-radius: 8px;
  height: 44px;
  object-fit: cover;
  padding: 4px;
  width: 44px;
}

.accounts_page .cart-product-detail .products .abt-product .pro-dtl {
  padding-left: 15px;
}

.accounts_page .cart-product-detail .products .abt-product .pro-dtl h5.name {
  color: #fff;
  font-family: "montserret_r";
  font-size: 14px;
  font-weight: 500;
  font-weight: 400;
  margin-bottom: 0;
}

.accounts_page .product-price {
  flex: 1 1;
}

.accounts_page .cart-product-detail .products .product-price h4.price {
  color: #aceb1e;
  display: inline-block;
  font-family: montserret_b;
  font-size: 16px;
  font-weight: 400;
  margin-left: 24px;
}

.accounts_page .cart-product-detail .products {
  align-items: center;
  border-bottom: 1px solid #70707073;
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  position: relative;
}

.accounts_page .cart-product-detail .pricing-details {
  border-bottom: 2px solid #c5c5c5;
  padding-bottom: 30px;
  padding-top: 20px;
}

.accounts_page .cart-product-detail .pricing-details .item-total {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.accounts_page .cart-product-detail .pricing-details .item-total h4.property {
  color: #fff;
  font-family: "montserret_m";
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.accounts_page .cart-product-detail .pricing-details .item-total h4.value {
  color: #fff;
  font-family: "montserret_m";
  font-size: 18px;
  font-weight: 400;
}

.accounts_page .cart-product-detail .order-total {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.accounts_page .cart-product-detail .order-total h3.property {
  color: #aceb1e;
  font-family: "montserret_m";
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.accounts_page .cart-product-detail .order-total h3.value {
  font-family: "montserret_b";
  font-weight: 400;
  font-size: 16px;
}

.accounts_page .cart-product-detail .checkout-products::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.accounts_page .cart-product-detail .checkout-products::-webkit-scrollbar {
  width: 3px;
  background: transparent;
}

.accounts_page .cart-product-detail .checkout-products::-webkit-scrollbar-thumb {
  background: #ACEB1E;
}

.accounts_page .modal-body.custommodal {
  background-color: #111419;
  border: 1px solid #98a1bc;
}

.accounts_page .modal-body.custommodal .title h3 {
  font-size: 20px;
}

@media(max-width:1440px) {
  .accounts_page .heading_top p {
    padding: 0 7rem;
  }
}

@media(max-width:1280px) {
  .accounts_page .heading_top:before {
    bottom: 40px;
  }

  .accounts_page .heading_top p {
    padding: 0 5rem;
  }
}

@media(max-width:1024px) {
  .accounts_page .heading_top p {
    padding: 0 2rem;
  }

  .accounts_page .heading_top:before {
    bottom: 47px;
  }
}