.twolinks {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    gap: 10px;
}

.twolinks a {
    font-size: 15px;
    font-family: 'worksans-sb';
    color: #fff;
}

.john-dow-all {
    text-align: center;
    border-bottom: 1px solid #70707038;
}

.john-dow-all .john-img {
    margin-top: -62px;
}

.john-dow-all .john-img img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #aceb1e;
}

.john-img {
    border-radius: 50%;
    width: fit-content;
    display: block;
    margin: auto;
}

.john-text h3 {
    font-size: 25px;
    color: #ACEB1E;
    font-family: 'montserret_b';
    /* font-weight: 700; */
}

.twolinks a.plant_btn {
    width: fit-content;
    margin-top: 2rem;
    font-family: 'worksans-r';
    color: #000;
    padding: 10px 26px;
    font-size: 13px;
    line-height: 1.5;
    color: #000;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border: 1px solid #ACEB1E;
    font-family: "worksans-sb";
}

.all-form-two .form-control {
    background: #111419;
    color: #fff;
    border: 1px solid #545a6a;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}